<template>
    <psi-dialog
        heading-color="accent darken-1"
        title="Add Students"
        scrollable
        icon="mdi-account-multiple-plus"
        @close="$emit('close')"
        @save="onSave"
    >
        <v-alert prominent outlined color="accent" class="mb-5">
            <p>
                Enter student name(s) and email(s) and we will send an
                invitation to complete their report.
            </p>
        </v-alert>
        <psi-form-section
            two-cols
            :value="data"
            name="student-data"
            :fields="studentFields"
            color="accent"
        ></psi-form-section>
        <div class="d-flex">
            <v-spacer></v-spacer>
            <v-tooltip top color="accent">
                <template v-slot:activator="{ attr, on }">
                    <v-btn
                        fab
                        color="accent"
                        v-on="on"
                        v-bind="attr"
                        @click.stop="addFields(1)"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span>Add Additional Students</span>
            </v-tooltip>
        </div>
        <v-snackbar v-model="snackbar" centered color="white">
            <v-card color="grey lighten-3">
                <v-card-title class="d-flex">
                    <span class="error--text text--darken-2">Form Errors</span>
                    <v-spacer></v-spacer>
                    <v-btn small text @click.stop="snackbar = false"
                        ><v-icon small class="grey--text text--darken-5"
                            >mdi-close</v-icon
                        ></v-btn
                    >
                    <v-card-text class="error--text text--darken-2">
                        {{ error }}
                    </v-card-text>
                </v-card-title>
            </v-card>
        </v-snackbar>
    </psi-dialog>
</template>
<script>
// TODO: add this to config

export default {
    name: "student-add",
    components: {},
    props: {},
    data() {
        return {
            fields: [],
            data: {},
            count: this.$config("program.initialStudentCount"),
            snackbar: false,
            error: "",
        };
    },
    computed: {
        studentFields() {
            return this.groupedFields(this.fields);
        },
    },
    watch: {},
    created() {},
    mounted() {
        this.addFields(this.count);
    },
    updated() {},
    detroyed() {},
    methods: {
        onSave() {
            this.error = "";
            Object.keys(this.data).forEach((key) => {
                if (
                    // Email field?
                    key.split("-").shift() === "email" &&
                    // Non-empty name w/ email field
                    this.data[key.replace("email", "name")].length == 0 &&
                    this.data[key].length > 0
                ) {
                    this.error = "Please provide a name for each valid email";
                    this.snackbar = true;
                    return false;
                }
            });
            if (this.error.length === 0) {
                let students = [];
                Object.keys(this.data).forEach((key) => {
                    if (
                        key.split("-").shift() === "email" &&
                        this.data[key].length !== 0
                    ) {
                        students.push({
                            name: this.data[key.replace("email", "name")],
                            email: this.data[key],
                        });
                    }
                });
                this.$emit("save", students);
            }
        },
        addFields(count) {
            const index = this.fields.length + 1;
            let fields = [];
            let data = {};

            for (let i = 0; i < count; i++) {
                const nameKey = `name-${index + i}`;
                const emailKey = `email-${index + i}`;
                fields.push(
                    {
                        type: "text",
                        name: nameKey,
                        key: nameKey,
                        label: "Name",
                        icon: this.$config("icons.name"),
                    },
                    {
                        type: "text",
                        name: emailKey,
                        format: "email",
                        key: emailKey,
                        label: "Email",
                        icon: this.$config("icons.email"),
                    }
                );
                data[nameKey] = "";
                data[emailKey] = "";
            }
            this.fields = this.fields.concat(fields);
            this.data = Object.assign(this.data, data);
        },
        groupedFields(fields) {
            // create a group of 2 column meta fields
            let fieldGroup = [];
            for (let i = 0; i < fields.length; i += 2) {
                let items = [];
                items.push(fields[i]);
                if (i + 1 < fields.length) {
                    items.push(fields[i + 1]);
                }
                fieldGroup.push(items);
            }
            return fieldGroup;
        },
    },
};
</script>

<style scoped>
</style>