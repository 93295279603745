var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-dialog",
    {
      attrs: {
        "heading-color": "accent darken-1",
        title: "Add Students",
        scrollable: "",
        icon: "mdi-account-multiple-plus"
      },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        },
        save: _vm.onSave
      }
    },
    [
      _c(
        "v-alert",
        {
          staticClass: "mb-5",
          attrs: { prominent: "", outlined: "", color: "accent" }
        },
        [
          _c("p", [
            _vm._v(
              " Enter student name(s) and email(s) and we will send an invitation to complete their report. "
            )
          ])
        ]
      ),
      _c("psi-form-section", {
        attrs: {
          "two-cols": "",
          value: _vm.data,
          name: "student-data",
          fields: _vm.studentFields,
          color: "accent"
        }
      }),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("v-spacer"),
          _c(
            "v-tooltip",
            {
              attrs: { top: "", color: "accent" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var attr = ref.attr
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: { fab: "", color: "accent" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.addFields(1)
                                }
                              }
                            },
                            "v-btn",
                            attr,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Add Additional Students")])]
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { centered: "", color: "white" },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: "grey lighten-3" } },
            [
              _c(
                "v-card-title",
                { staticClass: "d-flex" },
                [
                  _c("span", { staticClass: "error--text text--darken-2" }, [
                    _vm._v("Form Errors")
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", text: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.snackbar = false
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "grey--text text--darken-5",
                          attrs: { small: "" }
                        },
                        [_vm._v("mdi-close")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "error--text text--darken-2" },
                    [_vm._v(" " + _vm._s(_vm.error) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }